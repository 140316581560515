import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CDefinitionAndBtn,
  CDefinitionAndBtn02,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  CInfinitySlider,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
  CInfinitySlider02,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('yanone');
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/yanone/yanone_logo.svg',
            alt: '',
            widthSp: '252px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/yanone/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/yanone/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        tel={{
          number: '03-5643-8139',
          subText: '（直通）',
          note: '（受付時間 9:00～21:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>匠技が光る伝統的な江戸前寿司を心ゆくまで</>,
              text: (
                <>
                  江戸の粋を現代に生かした落ち着いた日本情緒あふれる空間。その日仕入れた魚の良さを板前に聞きながら楽しめるカウンター席や、テーブル席に個室など、ご家族でのお食事やお仕事での会食などさまざまな用途にご利用いただけます。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1 u_mb100"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone06__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yanone/img_yanone07.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/yanone/img_yanone07__sp.png',
                    alt: '',
                  },
                },
              ],
              text: (
                <>
                  丁寧な仕事で仕上げられた握りは見た目も端正な扇型で美しく、ネタとシャリのバランスが絶妙。全国各地から届いたネタを季節に合わせて味の個性を引き出し、独自の方法で炊き上げたこだわりの「しゃり」で旨さを生かす、職人の技をご堪能ください。
                </>
              ),
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          {/* <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p> */}
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'メニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['すし処 日本橋 矢の根寿司'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
        </LWrap>
      </section>
      <section className="l_sect u_pb40_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'PRIVATE ROOM',
              ja: '個室',
            }}
          />
          <CVariableSlider
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/yanone/img_yanone09.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/yanone/img_yanone10.png',
                  alt: '',
                },
              },
            ]}
          />
          <CShopPrivate
            data={shopdata}
            btn={{
              label: 'レストラン個室のご案内',
              link: {
                href: '/restaurants/private/',
              },
            }}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        tel={{
          number: '03-5643-8139',
          subText: '（直通）',
          note: '（受付時間 9:00～21:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
